import { useQuery } from "@apollo/client";
import { Accordion, AccordionDetails, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NotesEmptyState } from "@/assets/NotesEmptyState";
import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import {
    OnboardingNotes_SupplierFragment,
    OnboardingNotes_GetUserDocument,
    Onboarding_NoteFragment,
} from "@/gql/graphql";
import { getUserInfo } from "@/lib/token";
import { useAlert } from "@/providers";

import { AccordionSummary } from "..";

import { CreateNote } from "./CreateNoteRow";
import { NoteRow } from "./NoteRow";

interface OnboardingNotesProps {
    isEditor: boolean;
    supplier: OnboardingNotes_SupplierFragment;
    expanded: boolean;
    onChange: (_: React.SyntheticEvent, newExpanded: boolean) => void;
}

graphql(`
    fragment OnboardingNotes_Supplier on Supplier {
        id
        notes {
            ...Onboarding_Note
        }
    }

    fragment Onboarding_Note on Note {
        id
        note
        createdBy
        updatedBy
        type
        ...NoteRow_Note
    }

    query OnboardingNotes_GetUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...Note_RoleUser
            }
        }
    }
`);

export const OnboardingNotes: React.FC<OnboardingNotesProps> = ({ supplier, isEditor, onChange, expanded }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [userId, setUserId] = useState<string>("");
    useEffect(() => {
        getUserInfo().then(({ id }) => setUserId(id));
    }, []);

    const { data: usersData } = useQuery(OnboardingNotes_GetUserDocument, {
        variables: { input: {} },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to fetch users",
                    description: "Failed to fetch users alert message",
                }),
                severity: "error",
            });
        },
    });

    const [notes, setNotes] = useState<Onboarding_NoteFragment[]>(supplier?.notes);
    const sortableNotes = [...notes].filter((note) => !note.type || note.type === "Note");

    return (
        <Accordion
            sx={{
                borderRadius: 1,
                "&:before": {
                    display: "none",
                },
            }}
            expanded={expanded}
            onChange={(e) => onChange(e, !expanded)}
        >
            <AccordionSummary ariaControls="panel4-content" id="panel4-header">
                <Stack width="100%">
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Notes" description="Notes accordion title" />
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{ marginTop: -1 }} />
                {notes.length === 0 ? (
                    <Stack alignItems="center" mt={2}>
                        <NotesEmptyState />
                        <Typography variant="textMd" sx={{ mt: 2 }}>
                            {formatMessage({
                                defaultMessage: "Add a new note",
                                description: "Onboarding notes empty state",
                            })}
                        </Typography>
                        <Typography variant="textXs" color="textTextHelper" sx={{ mt: 1 }}>
                            {formatMessage({
                                defaultMessage: "Document all onboarding activity",
                                description: "Onboarding notes empty state description",
                            })}
                        </Typography>
                    </Stack>
                ) : (
                    <Stack spacing={2} marginTop={2}>
                        {sortableNotes
                            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                            .map((note) => {
                                const user = note.updatedBy
                                    ? usersData?.getUsers?.result?.find((user) => user.id === note.updatedBy)
                                    : usersData?.getUsers?.result?.find((user) => user.id === note.createdBy);
                                return (
                                    <NoteRow
                                        key={note.id}
                                        note={note}
                                        isEditor={isEditor}
                                        creator={user}
                                        canEdit={note.createdBy === userId}
                                        setNotes={setNotes}
                                    />
                                );
                            })}
                    </Stack>
                )}
                {supplier && (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <CreateNote
                            isEditor={isEditor}
                            supplierId={supplier.id}
                            onAdd={(newNote: Onboarding_NoteFragment) => {
                                setNotes((prevNotes) => [...prevNotes, newNote]);
                                return;
                            }}
                        />
                    </NoPermissionTooltip>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
