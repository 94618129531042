import type en from "@/lib/i18n/compiled/en-US.json";
import enUS from "@/lib/i18n/compiled/en-US.json";
import nbNO from "@/lib/i18n/compiled/nb-NO.json";

export type LocaleMessages = Partial<Omit<typeof en, "default">>;

function loadMessages(locale: string): LocaleMessages {
    switch (locale) {
        case "en-US":
            return enUS;
        case "nb-NO":
            return nbNO;
        default:
            return enUS;
    }
}

export { loadMessages };
