import { Plus } from "@ignite-analytics/icons";
import { Accordion, AccordionDetails, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DocumentsEmptyState } from "@/assets/DocumentsEmptyState";
import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { Documents_SupplierFragment } from "@/gql/graphql";

import { EmptyState } from "../EmptyState";
import { AccordionSummary } from "../index";

import { DocumentsList } from "./DocumentsList";
import { UploadModal } from "./UploadModal";

interface OnboardingDocumentsProps {
    open: boolean;
    onChange: (_: React.SyntheticEvent, newExpanded: boolean) => void;
    supplier: Documents_SupplierFragment;
    isEditor: boolean;
    refetch: () => void;
}

graphql(`
    fragment Documents_Supplier on Supplier {
        id
        files {
            ...DocumentsList_SupplierFile
        }
        assessmentsFiles {
            ...DocumentsList_AssessmentsFile
        }
    }

    fragment DocumentsActions_SupplierFile on SupplierFile {
        name
        ...DeleteModal_SupplierFile
    }

    fragment DeleteModal_SupplierFile on SupplierFile {
        name
        supplierId
        metaJson
    }
`);

export const OnboardingDocuments: React.FC<OnboardingDocumentsProps> = ({
    open,
    onChange,
    supplier,
    refetch,
    isEditor,
}) => {
    const { formatMessage } = useIntl();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const documents = [...supplier.files, ...supplier.assessmentsFiles];

    const handleUploadModalOpen = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent event from bubbling up to accordion
        setUploadModalOpen(true);
    };

    return (
        <Accordion
            sx={{
                borderRadius: 1,
                "&:before": {
                    display: "none",
                },
            }}
            expanded={open}
            onChange={(e) => onChange(e, !open)}
        >
            <AccordionSummary ariaControls="panel2-content" id="panel2-header">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Documents" description="Documents accordion title" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        {open && documents.length > 0 && (
                            <NoPermissionTooltip hasPermission={isEditor}>
                                <Button
                                    size="2xsmall"
                                    color="secondary"
                                    onClick={handleUploadModalOpen}
                                    startIcon={<Plus fontSize="inherit" />}
                                    disabled={!isEditor}
                                >
                                    <FormattedMessage defaultMessage="Add new" />
                                </Button>
                            </NoPermissionTooltip>
                        )}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {documents.length == 0 ? (
                    <EmptyState
                        text={formatMessage({
                            defaultMessage:
                                "Use this space to store documents like ISO certifications or signed code of conduct agreements.",
                            description: "Onboarding documents empty state",
                        })}
                        icon={<DocumentsEmptyState />}
                        isEditor={isEditor}
                        button={
                            <Button
                                size="xsmall"
                                color="secondary"
                                onClick={() => setUploadModalOpen(true)}
                                startIcon={<Plus fontSize="inherit" />}
                            >
                                <FormattedMessage defaultMessage="Upload document" />
                            </Button>
                        }
                    />
                ) : (
                    <DocumentsList files={documents} isLoading={false} supplierId={supplier.id} isEditor={isEditor} />
                )}

                <UploadModal
                    open={uploadModalOpen}
                    supplierId={supplier.id}
                    refetch={refetch}
                    onClose={() => setUploadModalOpen(false)}
                />
            </AccordionDetails>
        </Accordion>
    );
};
